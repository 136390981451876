import React from "react";
import Image from "next/image";
import Tag from "@/components/shared/Tag";
import Link from "next/link";
import { appendUtmParams } from "@/lib/utm";

const FooterLink = ({ label, href }: { label: string; href: string }) => (
  <Link
    href={appendUtmParams(href)}
    prefetch={false}
    className="font-ambit-regular md:text-md text-sm text-slate-950 transition-all duration-200 hover:opacity-70"
  >
    {label}
  </Link>
);

const FooterSection = ({
  title,
  links,
}: {
  title: string;
  links: Array<{ label: string; href: string }>;
}) => (
  <div className="flex flex-col items-start space-y-2">
    <p className="text-md font-ambit-semibold text-md md:text-lg">{title}</p>
    <div className="flex flex-col items-start space-y-2.5">
      {links.map((link, index) => (
        <FooterLink key={index} label={link.label} href={link.href} />
      ))}
    </div>
  </div>
);

const Footer = () => {
  const footerSections = [
    {
      title: "AI Workflows",
      links: [
        { label: "Fake texts video", href: "https://crayo.ai/create/text-video?step=1" },
        { label: "Reddit video", href: "https://crayo.ai/create/story-video?step=1" },
        { label: "ChatGPT video", href: "https://crayo.ai/create/chatgpt-video?step=1" },
        { label: "Split-screen video", href: "https://crayo.ai/create/split-video?step=1" },
        { label: "Auto-trim video", href: "https://crayo.ai/create/auto-trim-video?step=1" },
        { label: "Blur video", href: "https://crayo.ai/create/blur-video?step=1" },
      ],
    },
    {
      title: "Crayo Tools",
      links: [
        { label: "AI ideas", href: "https://crayo.ai/tools/ideas" },
        { label: "AI images", href: "https://crayo.ai/tools/images" },
        { label: "AI voiceovers", href: "https://crayo.ai/tools/voiceovers?step=0" },
        { label: "Youtube downloader", href: "https://crayo.ai/tools/youtube-downloader" },
        { label: "TikTok downloader", href: "https://crayo.ai/tools/youtube-downloader" },
      ],
    },
    {
      title: "Product",
      links: [
        { label: "Pricing", href: "https://crayo.ai/pricing" },
        { label: "Enterprise", href: "mailto:support@crayo.ai" },
        { label: "Guide", href: "https://crayo.ai/guide" },
      ],
    },
    {
      title: "Legal",
      links: [
        { label: "Refund policy", href: "https://crayo.ai/refund-policy" },
        { label: "Terms of service", href: "https://crayo.ai/terms-conditions" },
        { label: "Privacy policy", href: "https://crayo.ai/privacy-policy" },
        { label: "Affiliate TOS", href: "https://crayo.ai/affiliate-tos" },
      ],
    },
  ];

  return (
    <div className="relative m-3 overflow-hidden rounded-[20px] bg-[url('https://cdn-crayo.com/lp/public/heroBg.png')] bg-cover bg-center md:m-5 md:rounded-[40px]">
      <div className="absolute inset-x-0 bottom-0 h-full bg-[url('https://cdn-crayo.com/lp/public/footer-crayo.png')] bg-contain bg-bottom bg-no-repeat"></div>
      <footer className="container relative z-10 mx-auto space-y-8 px-6 py-12 md:space-y-12 md:px-6">
        <div className="flex w-full flex-col justify-between md:flex-row md:py-3">
          <div className="flex flex-col items-center space-y-5 md:w-1/3 md:items-start">
            <Link href="/" className="">
              <Image
                src="https://cdn-crayo.com/lp/public/crayoBlue.png"
                alt="CRAYO Logo"
                width={92}
                height={22}
              />
            </Link>
            <p className="text-md text-center md:text-start md:text-lg">
              Crayo is the fastest, adaptable short-form generation platform for your viral content
              needs.
            </p>

            <Link
              href="https://crayo.ai/discord"
              className="inline-flex transition-all duration-200 hover:opacity-70"
            >
              <Tag
                icon="https://cdn-crayo.com/lp/public/footer-discord.png"
                label="Join our Discord community"
              />
            </Link>

            <div className="flex flex-row space-x-5">
              <button className="transition-all duration-200 hover:opacity-70">
                <Link
                  href="https://www.instagram.com/crayoai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="https://cdn-crayo.com/lp/public/footer-instagram.png"
                    alt="Crayo Instagram"
                    width={16}
                    height={16}
                  />
                </Link>
              </button>
              <button className="transition-all duration-200 hover:opacity-70">
                <Link href="https://twitter.com/crayoai" target="_blank" rel="noopener noreferrer">
                  <Image
                    src="https://cdn-crayo.com/lp/public/footer-x.png"
                    alt="Crayo Twitter"
                    width={16}
                    height={16}
                  />
                </Link>
              </button>
            </div>
          </div>

          <div className="grid w-full grid-cols-2 items-center justify-end gap-y-5 pt-12 sm:grid-cols-2 md:flex md:flex-row md:items-start md:space-x-[60px] md:space-y-0 md:pt-0">
            {footerSections.slice(0, 2).map((section, index) => (
              <FooterSection key={index} title={section.title} links={section.links} />
            ))}
            <div className="col-span-2 grid grid-cols-2 sm:grid-cols-2 md:flex md:flex-col md:space-y-4">
              {footerSections.slice(2).map((section, index) => (
                <FooterSection key={index} title={section.title} links={section.links} />
              ))}
            </div>
          </div>
        </div>

        <div className="mb-0 mt-4 flex w-full flex-col items-center justify-between space-y-2 md:mb-4 md:mt-0 md:flex-row md:space-y-0">
          <p className="font-ambit-regular text-sm md:text-lg">Copyright © 2024 Crayo, Inc.</p>
          <div className="flex flex-row space-x-5 md:justify-center md:space-x-5">
            <button className="transition-all duration-200 hover:opacity-70">
              <Link href="https://crayo.tolt.io" target="_blank" rel="noopener noreferrer">
                <p className="text-md font-ambit-regular text-xs md:text-lg">Affiliate Program</p>
              </Link>
            </button>
            <button className="transition-all duration-200 hover:opacity-70">
              <Link
                prefetch={false}
                href="https://crayo.ai/discord"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="text-md font-ambit-regular text-xs md:text-lg">Discord</p>
              </Link>
            </button>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
